import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const CanIChangeTheOdds = ({ location }) => {
  const title = "インスタントウィン開催中に当選確率を変更する方法"
  const list = [
    { title: "よくある質問", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="PARKLoTのキャンペーンでは、開催中でも当選確率を変更することが可能です。キャンペーンの状況に応じて当選確率を上下させることができます。景品を余らせることなく参加者に配布できます。"
          pagepath={location.pathname}
        />
        <Breadcrumb title="よくある質問" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              キャンペーンの開催中に当選確率を変更することは可能ですか？
            </h1>
            <p className="qa-page__answer">
              はい、当選確率はキャンペーンの途中でも変更することが可能です。
              <br />
              ただし、キャンペーンの途中でプレゼントの内容を変更することはできません。
            </p>
            <p className="qa-page__text">
              確率によりランダムで当選者を抽選しているため、当選者に偏りがでることがございます。
              そんな時は、管理画面から確率を変更することで対応できます。
            </p>
            <p className="qa-page__text">
              せっかく用意したプレゼントが余ることがないよう、うまくすべての商品が参加者に行き渡るようにコントロールし、キャンペーンを成功に導きましょう。
            </p>

            <div className="qa-page__detail-container">
              <div>
                <h2 className="qa-page__h2">当選確率の変更方法</h2>
                <p className="qa-page__text">
                  当選確率はキャンペーンの管理画面でプレゼントごとに異なる確率をセットできます。
                </p>
              </div>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default CanIChangeTheOdds
